import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout/layout'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        allProject {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        allFile(filter: {sourceInstanceName: {eq: "projects"}}) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <div id='main'>
          <section id='projects'>
            <div className='container'>
              <div className='row'>
                {data.allProject.edges.map(edge => {
                  const project = edge.node
                  return (
                    <div key={project.id} className='col-md-3'>
                      <Link to={`/project/${project.slug}/`} className='project-item'>
                        <div className='img-block'>
                          {data.allFile.edges.filter(img => (
                            img.node.name === project.slug || img.node.name === `${project.slug}-mobile`
                            )).map(img => {
                              return <Img 
                                key={img.node.id} 
                                alt={project.name}
                                title={project.name}
                                className={img.node.name === project.slug ? 'default' : 'hover'}
                                fluid={img.node.childImageSharp.fluid} 
                              />
                            })
                          }
                        </div>
                        <h2>{project.name}</h2>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )}
  />
)

export default IndexPage
